<template>
  <div
    v-if="item"
    :key="slug"
    class="reminders"
  >
    <header class="reminders-header">
      <div id="header-wrapper">
        <h1 class="reminders-header-title">
          <title-with-client :title="item.title" />
          <select-filter
            v-if="slug !== 'assess'"
            v-model="selectedFilter"
          />
        </h1>
      </div>
    </header>
    <section
      ref="remindersBody"
      class="reminders-body"
    >
      <div
        ref="remindersBodyHelper"
        class="reminders-body-helper"
      >
        <div
          v-for="(reminder, index) in filteredReminders"
          :key="reminder.id"
          class="reminders-body-item"
        >
          <nav-reminder
            v-if="!reminder.isDemo"
            :reminder="reminder"
            :reminder-class="index % 2 ? 'reminder-odd' : 'reminder-even'"
            @click="reminderClickHandler"
          />
        </div>
      </div>
    </section>

    <base-modal
      v-if="dialog && selectedReminder"
      :reminder="selectedReminder"
      :dialog="dialog"
      :fullscreen="selectedReminder.isDemo"
      @modal-close="dialogClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import format from 'string-template'
import reminders from '@/kit/dictionaries/reminders'
import NavReminder from '@/components/NavReminder'
import BaseModal from '@/components/BaseModal'
import TitleWithClient from '@/views/dashboard/TitleWithClient'
import SelectFilter from '@/components/SelectFilter'

export default {
  name: 'Reminders',
  components: {
    NavReminder,
    BaseModal,
    TitleWithClient,
    SelectFilter
  },
  data: () => ({
    slug: null,
    item: null,
    dialog: false,
    selectedReminder: null,
    selectedFilter: null,
    remindersBodyHelperOffset: {}
  }),
  computed: {
    ...mapGetters('clients', ['client']),
    title() {
      return format(this.item.title, this.client)
    },
    filteredReminders() {
      // Technically this component also handles Practice and Assess types too

      // Handle "any" case (any's value is typeof null)
      if (this.selectedFilter === null) {
        return this.item.reminders
      }

      // Filter items (exercises) out of each reminder that don't belong in the filter
      const reminders = this.item.reminders.reduce((accum, reminder) => {
        const clonedReminder = cloneDeep(reminder)

        clonedReminder.items = clonedReminder.items
          .map(i => {
            // Handle 'any' case for this item specifically
            if (!i.audience || !i.audience.length)
              return i
            
            // Item is included in filter
            if (i.audience.includes(this.selectedFilter))
              return i

            // Not in filter and not in "any" category
            return null
          })
          .filter(i => !!i) // Remove nulls
        
        // Only include the reminder if it has items
        if (clonedReminder.items?.length > 0) {
          accum.push(clonedReminder)
          return accum
        }
        
        return accum
      }, [])
      
      return reminders
    }
  },
  watch: {
    $route() {
      this.slug = this.$route.params.slug

      this.getItem()
    },
    'client.id': function() {
      if (!this.client.id) {
        this.$router.push({ name: 'patientSelect' })
      }
    }
  },
  created() {
    this.slug = this.$route.params.slug
  },
  mounted() {
    this.getItem()
  },
  methods: {
    getItem() {
      [this.item] = reminders.filter(item => item.slug === this.slug)
    },
    reminderClickHandler(item) {
      this.selectedReminder = {
        ...item,
        // Purpose of below depredated statement unknown:
        // reminderType: item.reminderType || 'remind',
        reminderType: this.slug,
        items: item.items.map(el => ({
          ...el,
          notificationConfig: { ...el.notificationConfig }
        }))
      }
      this.dialog = true
    },
    dialogClose() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">

.reminders {
  &-header {
    background-color: #fff;
    margin-bottom: 40px;
    margin-top: 0;
    padding-top: 0.25rem;
    position: sticky;
    top: 0;
    z-index: 100;

    @media (max-width: 599px) {
      margin-bottom: 24px;
    }

    &-title {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      font-size: 24px;
      font-weight: 700;
      justify-content: space-between;
      line-height: 1.07;
      margin: 2.15rem 1rem 2rem 1rem;
      text-align: left;

      .title-wrapper {
        margin-bottom: 1rem;
      }

      a {
        color: var(--v-primary-base);
      }
    }
  }

  &-body {
    // max-width: 928px;
    margin: 0 auto;
    @media (max-width: 1260px) {
      max-width: 696px;
    }
    @media (max-width: 1035px) {
      max-width: 470px;
    }

    @media (max-width: 730px) {
      max-width: 465px;
    }

    &-helper {
      position: relative;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 599px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &-item {
      display: inline;
      vertical-align: top;
      max-width: 225px;
    }
  }
}
</style>
