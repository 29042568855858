<template>
  <span class="title-wrapper">
    {{ titles[0] }}
    <template v-if="client.id">
      <router-link
        v-if="link"
        :to="{ name: 'clientEdit', params: { id: client.id } }"
        class="text-decoration-none"
      >
        {{ name }}
      </router-link>
      <span 
        v-else-if="
          $route.name === 'review' || 
            $route.name === 'reviewAssess' ||
            $route.name === 'reviewSleepLog'
        "
      >
        {{ name }}
      </span>
      <span v-else />
    </template>
    <!-- <template v-else>
      your {{ clientPhrase }}
    </template> -->
    {{ titles[1] }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TitleWithClient',
  props: {
    plural: {
      type: Boolean,
      required: false,
      default: () => false
    },
    link: {
      type: Boolean,
      required: false,
      default: () => false
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('clients', ['client']),
    ...mapGetters('authentication', ['clientPhrase']),
    name() {
      return this.client.name + (this.plural ? '’s' : '')
    },
    titles() {
      return this.title.split('{name}')
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
