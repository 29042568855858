<template>
  <div style="position: relative; z-index: 0;">
    <v-icon
      ref="infoIcon"
      class="info-icon"
      aria-hidden="false"
      size="32px"
      @click="displayDescription"
    >
      {{ mdiInformation }}
    </v-icon>
    <v-card
      ref="reminder"
      elevation="0"
      :class="['reminder', reminderClass]"
      v-on="inputListeners"
    >
      <div
        class="reminder-image"
      >
        <img
          :src="getImageUrl(reminder.image)"
          :alt="reminder.title"
        >
      </div>
      <div
        class="reminder-text text-center"
      >
        {{ reminder.title }}
      </div>

      <div
        class="reminder-description text-center"
      >
        {{ reminder.description }}
      </div>
    </v-card>
  </div>
</template>

<script>
/* mdi is material design icon library */
import { mdiInformation } from '@mdi/js'
export default {
  props: {
    reminder: {
      type: Object,
      default: () => ({})
    },
    reminderClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    mdiInformation
  }),
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        click: () => this.$emit('click', this.reminder)
      }
    }
  },
  methods: {
    getImageUrl(data) {
      return require('@/assets/img/' + data)
    },
    displayDescription() {
      /* toggle description and icon to control display of both
      description text but to also update button color */
      this.$refs.reminder.$el.classList.toggle('description')
      this.$refs.infoIcon.$el.classList.toggle('active')
    }
  }
}
</script>

<style lang="scss" scoped>

.info-icon {
  color: rgba(154, 166, 182, 0.44);
  caret-color: rgba(154, 166, 182, 0.44);
  cursor: pointer;
  left: 174px;
  position: absolute;
  top: 12px;
  z-index: 1;
}

.info-icon.active {
  color: var(--v-primary-base)!important;
  caret-color: var(--v-primary-base) !important;
}

.reminder {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--v-neutralMedium-base);
  align-items: center;
  width: 208px;
  height: 200px;
  margin: 0 12px 24px 12px;
  padding: 32px 16px 24px 16px;
  border-radius: 25px !important;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;

  &-image {
    max-width: 104px;

    img {
      width: 100%;
    }
  }

  &-text {
    font-size: 15px;
    font-weight: 500;
    margin-top: 1rem;
  }

  &-description {
    display: none;
  }
}

.description {

  .reminder-image {
    left: -10%;
    top: -15%;
    position: absolute;
    transform: scale(.35);

    img {
      width: 100%;
    }
  }

  .reminder-description {
    display: block;
    font-size: 0.875rem;
    line-height: 1.25;
  }
}
</style>
